<script>
import {
  required,
  // email,
  //   minLength,
  // sameAs,
  //   maxLength,
  // minValue,
  // maxValue,
  // numeric,
  // url,
  // alphaNum,
} from "vuelidate/lib/validators";

import Swal from "sweetalert2";
import useNetw from "@useNetw";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";

/**
 * Form validation component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Multiselect,
    /*DatePicker,
    Switches,
    NumberInputSpinner,*/
  },
  page: {
    title: "แก้ไขงานนอก",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },

  computed: {
    /**
     * Total no. of records
     */
    //
  },


  created() {
    this.getLocalData();
    this.showOS();
    
  },

  data() {
    return {
      submitformOS: false,

      title: "แก้ไขงานนอก",
      items: [
        {
          text: "ข้อมูลหลัก",
        },
        {
          text: "ศูนย์บริการ",
        },
        {
          text: "งานนอก",
          href:"/outsourcing-service"
        },
        {
          text: "แก้ไขงานนอก",
        },
      ],

      loading: undefined,
      optionsSub: [],
      localDataBranch: [],
      localData: [],
      osId:'',

      error: "กรุณาตรวจสอบข้อมูลอีกครั้ง.",

      formOS: {
        branchId: "",
        osCode: "",
        nameTh: "",
        nameEn: "",
        price: "",
        workDuration: "",
        supId: "",
      },
    };
  },
  validations: {
    formOS: {
      branchId: {
        required,
      },
      osCode: {},
      nameTh: {
        required,
      },
      nameEn: {},
      price: {},
      workDuration: {},
      supId: {},
    },
  },

  methods: {

      putBranchOS: function() {
      useNetw
        .put("api/outsourcing-service/update", {
          osId: this.osId,
          branchId: this.formOS.branchId.branchId,
          osCode:this.formOS.osCode,
          nameTh:this.formOS.nameTh,
          nameEn:this.formOS.nameEn,
          price:this.formOS.price,
          workDuration:this.formOS.workDuration,
          supId:this.formOS.supId.supId == undefined ? '' : this.formOS.supId.supId,
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.getSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );this.$router.push({ name: "outsourcing-service" });
        })
        .catch(() => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify('กรุณาระบุชื่อสาขา'),
            appConfig.swal.type.error
          );
        })
        .then(() => {});
    },
    showOS(){
        this.loading = true; //skeleton true
        useNetw
        .get("api/outsourcing-service/show", {
          params: {
            osId: this.$route.params.osId,
          },
        })
        .then((response) => {
        //   console.log('OS =>', response.data.data);
        this.osId = response.data.data.osId;

          this.formOS.branchId={
              branchId: response.data.data.branchId,
              nameTh: response.data.data.branchName
          };
          this.formOS.osCode = response.data.data.osCode;
          this.formOS.nameTh = response.data.data.nameTh;
          this.formOS.nameEn = response.data.data.nameEn;
          this.formOS.price = response.data.data.price;
          this.formOS.workDuration = response.data.data.workDuration;

          this.formOS.supId = {
              supId: response.data.data.supId,
              nameTh: response.data.data.supplierNameTh
          }
          this.getSup()

        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },

    getLocalData() {
      const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
      this.localData = user.userAccessBranch;
      const localDataBranch = [];
      this.localData.forEach((item) => {
        localDataBranch.push(item.branchId);
      });
      this.localDataBranch = localDataBranch;
      this.branchId = user.branchId;
    },

    formOSSubmit() {
      this.$v.$touch();

      if (this.$v.formOS.$invalid) {
        // console.log("false");
        this.submitformOS = true;
      } else {
        this.putBranchOS();
      }
    },

    getSup: function() {
      // this.overlayFlag = true;
      useNetw
        .get("api/master/suppliers", {
          params: {
            branchId: this.formOS.branchId.branchId,
          },
        })
        .then((response) => {
          this.optionsSub = response.data.data;
          // console.log(response);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
        });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <b-skeleton-wrapper :loading="loading"
          ><!--// !skeleton start-->
          <template #loading>
            <br />
            <b-skeleton animation="wave" width="10%"></b-skeleton>
            <b-skeleton animation="wave" width="15%"></b-skeleton>

            <br />
            <div class="row">
              <div class="col-md-2">
                <b-skeleton type="input" animation="throb"></b-skeleton>
              </div>
              <div class="col-md-3">
                <b-skeleton type="input" animation="throb"></b-skeleton>
              </div>
            </div>
            <br />
            <div class="row">
              <br />
              <div class="col-md-3">
                <b-skeleton type="input" animation="throb"></b-skeleton>
              </div>
              <div class="col-md-3">
                <b-skeleton type="input" animation="throb"></b-skeleton>
              </div>
              <div class="col-md-3">
                <b-skeleton type="input" animation="throb"></b-skeleton>
              </div>
            </div>
            <br />

            <div class="card">
              <b-skeleton-table
                :rows="5"
                :columns="2"
                :table-props="{ bordered: false, striped: true }"
              ></b-skeleton-table>
            </div>
          </template>
          <!--// !skeleton ends-->

          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-6 my-auto">
                  <!-- <h4 class="card-title">เพิ่มสาขาย่อย</h4> -->
                </div>
                <div class="col-md-6 text-end">
                  <router-link :to="{ name: 'outsourcing-service' }">
                    <button class="btn btn-secondary">กลับสู่หน้าหลัก</button>
                  </router-link>
                </div>
              </div>
              <hr />
              <form class="needs-validation" @submit.prevent="formOSSubmit()">
                <div class="row">
                  <div class="col-md-6">
                    <code>* </code><label for="branchId">สาขา :</label>
                    <multiselect
                      v-model="formOS.branchId"
                      :options="localData"
                      label="nameTh"
                      
                      :class="{
                        'is-invalid': submitformOS && $v.formOS.branchId.$error,
                      }"
                    >
                    </multiselect>
                    <div
                      v-if="submitformOS && $v.formOS.branchId.$error"
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.formOS.branchId.required">{{
                        error
                      }}</span>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <code>* </code
                    ><label for="branchId">บริษัทที่รับผิดชอบ :</label>
                    <multiselect
                      v-model="formOS.supId"
                      :options="optionsSub"
                      label="nameTh"
                      :class="{
                        'is-invalid': submitformOS && $v.formOS.supId.$error,
                      }"
                    >
                    </multiselect>
                    <div
                      v-if="submitformOS && $v.formOS.supId.$error"
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.formOS.supId.required">{{ error }}</span>
                    </div>
                  </div>
                </div>
                <!--//! End Rows -->

                <div class="row mt-3">
                  <div class="col-md-2">
                    <div class="mb-3 position-relative">
                      <code>* </code
                      ><label for="dealerBranchId">รหัสงานนอก :</label>
                      <input
                        v-model="formOS.osCode"
                        type="text"
                        class="form-control"
                        placeholder="รหัสงานนอก"
                        :class="{
                          'is-invalid': submitformOS && $v.formOS.osCode.$error,
                        }"
                      />
                      <div
                        v-if="submitformOS && $v.formOS.osCode.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.formOS.osCode.required">{{
                          error
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-5">
                    <div class="mb-3 position-relative">
                      <code>* </code
                      ><label for="branchNameTH">ชื่องานนอก (ไทย)  :</label>
                      <input
                        v-model="formOS.nameTh"
                        type="text"
                        class="form-control"
                        placeholder="ชื่องานนอก (ไทย)"
                        :class="{
                          'is-invalid': submitformOS && $v.formOS.nameTh.$error,
                        }"
                      />
                      <div
                        v-if="submitformOS && $v.formOS.nameTh.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.formOS.nameTh.required">{{
                          error
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-5">
                    <div class="mb-3 position-relative">
                      <code>* </code
                      ><label for="validationformdealerbranchNameEN"
                        >ชื่องานนอก (อังกฤษ)</label
                      >
                      <input
                        v-model="formOS.nameEn"
                        type="text"
                        class="form-control"
                        placeholder="ชื่องานนอก (อังกฤษ)"
                        :class="{
                          'is-invalid': submitformOS && $v.formOS.nameEn.$error,
                        }"
                      />
                      <div
                        v-if="submitformOS && $v.formOS.nameEn.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.formOS.nameEn.required">{{
                          error
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- //!End row -->

                <div class="row">
                  <div class="col-md-2">
                    <div class="mb-3 position-relative">
                      <code>* </code
                      ><label for="validationformdealerbranchShortNameEN"
                        >ราคา (บาท)
                       :</label>
                      <input
                        v-model="formOS.price"
                        type="number"
                        class="form-control"
                        placeholder="0"
                        :class="{
                          'is-invalid': submitformOS && $v.formOS.price.$error,
                        }"
                      />
                      <div
                        v-if="submitformOS && $v.formOS.price.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.formOS.price.required">{{
                          error
                        }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2">
                    <div class="mb-3 position-relative">
                      <code>* </code
                      ><label for="validationformdealerbranchShortNameEN"
                        >จำนวนชั่วโมงทำงาน
                       :</label>
                      <input
                        v-model="formOS.workDuration"
                        type="number"
                        class="form-control"
                        placeholder="0"
                        :class="{
                          'is-invalid':
                            submitformOS && $v.formOS.workDuration.$error,
                        }"
                      />
                      <div
                        v-if="submitformOS && $v.formOS.workDuration.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.formOS.workDuration.required">{{
                          error
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="text-end">
                  <button type="submit" class="btn btn-success">บันทึก</button>
                </div>
                <hr />

                
              </form>
              
            
            </div>
          </div>
          <!-- //!end card -->
        </b-skeleton-wrapper>
      </div>
    </div>
  </Layout>
</template>
