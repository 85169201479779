var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[_c('PageHeader',{attrs:{"title":_vm.title,"items":_vm.items}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('b-skeleton-wrapper',{attrs:{"loading":_vm.loading},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('br'),_c('b-skeleton',{attrs:{"animation":"wave","width":"10%"}}),_c('b-skeleton',{attrs:{"animation":"wave","width":"15%"}}),_c('br'),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-2"},[_c('b-skeleton',{attrs:{"type":"input","animation":"throb"}})],1),_c('div',{staticClass:"col-md-3"},[_c('b-skeleton',{attrs:{"type":"input","animation":"throb"}})],1)]),_c('br'),_c('div',{staticClass:"row"},[_c('br'),_c('div',{staticClass:"col-md-3"},[_c('b-skeleton',{attrs:{"type":"input","animation":"throb"}})],1),_c('div',{staticClass:"col-md-3"},[_c('b-skeleton',{attrs:{"type":"input","animation":"throb"}})],1),_c('div',{staticClass:"col-md-3"},[_c('b-skeleton',{attrs:{"type":"input","animation":"throb"}})],1)]),_c('br'),_c('div',{staticClass:"card"},[_c('b-skeleton-table',{attrs:{"rows":5,"columns":2,"table-props":{ bordered: false, striped: true }}})],1)]},proxy:true}])},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 my-auto"}),_c('div',{staticClass:"col-md-6 text-end"},[_c('router-link',{attrs:{"to":{ name: 'outsourcing-service' }}},[_c('button',{staticClass:"btn btn-secondary"},[_vm._v("กลับสู่หน้าหลัก")])])],1)]),_c('hr'),_c('form',{staticClass:"needs-validation",on:{"submit":function($event){$event.preventDefault();return _vm.formOSSubmit()}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('code',[_vm._v("* ")]),_c('label',{attrs:{"for":"branchId"}},[_vm._v("สาขา :")]),_c('multiselect',{class:{
                      'is-invalid': _vm.submitformOS && _vm.$v.formOS.branchId.$error,
                    },attrs:{"options":_vm.localData,"label":"nameTh"},model:{value:(_vm.formOS.branchId),callback:function ($$v) {_vm.$set(_vm.formOS, "branchId", $$v)},expression:"formOS.branchId"}}),(_vm.submitformOS && _vm.$v.formOS.branchId.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.formOS.branchId.required)?_c('span',[_vm._v(_vm._s(_vm.error))]):_vm._e()]):_vm._e()],1),_c('div',{staticClass:"col-md-6"},[_c('code',[_vm._v("* ")]),_c('label',{attrs:{"for":"branchId"}},[_vm._v("บริษัทที่รับผิดชอบ :")]),_c('multiselect',{class:{
                      'is-invalid': _vm.submitformOS && _vm.$v.formOS.supId.$error,
                    },attrs:{"options":_vm.optionsSub,"label":"nameTh"},model:{value:(_vm.formOS.supId),callback:function ($$v) {_vm.$set(_vm.formOS, "supId", $$v)},expression:"formOS.supId"}}),(_vm.submitformOS && _vm.$v.formOS.supId.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.formOS.supId.required)?_c('span',[_vm._v(_vm._s(_vm.error))]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"row mt-3"},[_c('div',{staticClass:"col-md-2"},[_c('div',{staticClass:"mb-3 position-relative"},[_c('code',[_vm._v("* ")]),_c('label',{attrs:{"for":"dealerBranchId"}},[_vm._v("รหัสงานนอก :")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formOS.osCode),expression:"formOS.osCode"}],staticClass:"form-control",class:{
                        'is-invalid': _vm.submitformOS && _vm.$v.formOS.osCode.$error,
                      },attrs:{"type":"text","placeholder":"รหัสงานนอก"},domProps:{"value":(_vm.formOS.osCode)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formOS, "osCode", $event.target.value)}}}),(_vm.submitformOS && _vm.$v.formOS.osCode.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.formOS.osCode.required)?_c('span',[_vm._v(_vm._s(_vm.error))]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-md-5"},[_c('div',{staticClass:"mb-3 position-relative"},[_c('code',[_vm._v("* ")]),_c('label',{attrs:{"for":"branchNameTH"}},[_vm._v("ชื่องานนอก (ไทย) :")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formOS.nameTh),expression:"formOS.nameTh"}],staticClass:"form-control",class:{
                        'is-invalid': _vm.submitformOS && _vm.$v.formOS.nameTh.$error,
                      },attrs:{"type":"text","placeholder":"ชื่องานนอก (ไทย)"},domProps:{"value":(_vm.formOS.nameTh)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formOS, "nameTh", $event.target.value)}}}),(_vm.submitformOS && _vm.$v.formOS.nameTh.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.formOS.nameTh.required)?_c('span',[_vm._v(_vm._s(_vm.error))]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-md-5"},[_c('div',{staticClass:"mb-3 position-relative"},[_c('code',[_vm._v("* ")]),_c('label',{attrs:{"for":"validationformdealerbranchNameEN"}},[_vm._v("ชื่องานนอก (อังกฤษ)")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formOS.nameEn),expression:"formOS.nameEn"}],staticClass:"form-control",class:{
                        'is-invalid': _vm.submitformOS && _vm.$v.formOS.nameEn.$error,
                      },attrs:{"type":"text","placeholder":"ชื่องานนอก (อังกฤษ)"},domProps:{"value":(_vm.formOS.nameEn)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formOS, "nameEn", $event.target.value)}}}),(_vm.submitformOS && _vm.$v.formOS.nameEn.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.formOS.nameEn.required)?_c('span',[_vm._v(_vm._s(_vm.error))]):_vm._e()]):_vm._e()])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-2"},[_c('div',{staticClass:"mb-3 position-relative"},[_c('code',[_vm._v("* ")]),_c('label',{attrs:{"for":"validationformdealerbranchShortNameEN"}},[_vm._v("ราคา (บาท) :")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formOS.price),expression:"formOS.price"}],staticClass:"form-control",class:{
                        'is-invalid': _vm.submitformOS && _vm.$v.formOS.price.$error,
                      },attrs:{"type":"number","placeholder":"0"},domProps:{"value":(_vm.formOS.price)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formOS, "price", $event.target.value)}}}),(_vm.submitformOS && _vm.$v.formOS.price.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.formOS.price.required)?_c('span',[_vm._v(_vm._s(_vm.error))]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-md-2"},[_c('div',{staticClass:"mb-3 position-relative"},[_c('code',[_vm._v("* ")]),_c('label',{attrs:{"for":"validationformdealerbranchShortNameEN"}},[_vm._v("จำนวนชั่วโมงทำงาน :")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formOS.workDuration),expression:"formOS.workDuration"}],staticClass:"form-control",class:{
                        'is-invalid':
                          _vm.submitformOS && _vm.$v.formOS.workDuration.$error,
                      },attrs:{"type":"number","placeholder":"0"},domProps:{"value":(_vm.formOS.workDuration)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formOS, "workDuration", $event.target.value)}}}),(_vm.submitformOS && _vm.$v.formOS.workDuration.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.formOS.workDuration.required)?_c('span',[_vm._v(_vm._s(_vm.error))]):_vm._e()]):_vm._e()])])]),_c('div',{staticClass:"text-end"},[_c('button',{staticClass:"btn btn-success",attrs:{"type":"submit"}},[_vm._v("บันทึก")])]),_c('hr')])])])])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }